import React from 'react'

// import { Header } from './_header'

// eslint-disable-next-line import/no-default-export
export default function NotFound() {
  if (typeof window !== 'undefined') {
    window.location = '/'
  }

  return null
}
